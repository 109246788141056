import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getTasks, getDomains } from "../functions/getData";
import { showWarningSwal } from "../functions/alert";
import { populateTask, deepCopy } from "../functions/edit";
import Task from "../components/EditTask/Task";
import NunitoText from "../components/general/NunitoText";
import ReusableButton from "../components/general/ReusableButton";
import Loader from "../components/general/Loader";
import NoData from "../components/general/NoData";
export default function EditTask() {
  const [loading, setLoading] = useState(true);
  const [firstLoaded, setFirstLoaded] = useState(false);
  const [original, setOriginal] = useState();
  const [task, setTask] = useState();
  const [allTopicOptions, setAllTopicOptions] = useState([]);
  const [editAnswers, setEditAnswers] = useState(false);
  const [tagOptions, setTagOptions] = useState([]);
  const [isVerfiy, setIsVerfiy] = useState(false);
  const [IsReadOnly, setIsReadOnly] = useState(false);
  document.title = "Edit Task";
  let location = useLocation();
  console.log(
    location.search.slice(
      location.search.length - 3,
      location.search.length
    ) === "mod"
  );
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (location.search.slice(0, 4) === "?id=") {
      if (
        location.search.slice(
          location.search.length - 3,
          location.search.length
        ) === "mod"
      ) {
        setIsReadOnly(true);
        console.log("in");
      } else if (
        location.search.slice(
          location.search.length - 3,
          location.search.length
        ) === "ver"
      ) {
        setIsReadOnly(true);
        setIsVerfiy(true);
      }
      getTasks(`?id=${location.search.slice(4)}`, signal)
        .then((c) => {
          console.log(c);
          setEditAnswers(c.taskLists.data[0].allow_update_answer);
          let foundTasks = populateTask(c.taskLists);
          setOriginal(deepCopy(foundTasks));
          setTask(foundTasks);
          getDomains("?limits=50&status=active").then((d) => {
            let domains = d.tagLists.data.filter(
              (c) => !c.is_tag && c.domain_name === null
            );
            let topics = d.tagLists.data.filter(
              (c) => !c.is_tag && c.domain_name !== null
            );
            let outputTopics = domains.map((d) => ({
              id: d.id,
              option: d.name,
              children: [],
            }));
            outputTopics.forEach((o) => {
              topics.forEach((t) => {
                if (t.domain_id === o.id)
                  o.children.push({ id: t.id, option: t.name });
              });
            });
            setAllTopicOptions(outputTopics);
            setTagOptions(d.tagLists.data.filter((c) => c.is_tag));
          });
        })
        .catch((e) => console.log(e))
        .finally(() => {
          if (!signal.aborted) {
            setLoading(false);
            setFirstLoaded(true);
          }
        });
    } else {
      showWarningSwal("Wrong query");
      if (!signal.aborted) {
        setLoading(false);
        setFirstLoaded(true);
      }
    }
    return () => controller.abort();
  }, [location.search]);
  return (
    <Box className="wrapperBox">
      <div className="firstRowContainer dashboardAndSelfBtnDiv">
        <ReusableButton
          text="Dashboard"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          width={125}
          br={18}
          to="/dashboard"
          iconType="home"
        />
        <ChevronRightIcon />
        <ReusableButton
          text="Tasks"
          fontSize={14}
          bgColor="#F16774"
          height={36}
          br={18}
          to="/task"
        />
      </div>
      <div className="formContainer">
        <NunitoText
          value={isVerfiy ? "Verfiy Task" : "Moderate Task"}
          fontSize={40}
          fontWeight={700}
          italic
          color="#144A94"
          align="left"
        />
        {loading ? (
          <Loader height={600} />
        ) : task ? (
          <Task
            original={original}
            setOriginal={setOriginal}
            task={task}
            setTask={setTask}
            editAnswers={editAnswers}
            IsReadOnly={IsReadOnly}
            isVerfiy={isVerfiy}
            allTopicOptions={allTopicOptions}
            tagOptions={tagOptions}
          />
        ) : (
          firstLoaded && <NoData height={600} />
        )}
      </div>
    </Box>
  );
}
