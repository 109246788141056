import React from 'react'
import { Grid, IconButton } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
const buttonStyle = (isSelected, url, active) => ({
    backgroundColor: isSelected && '#144A94', borderRadius: 32, width: 32,
    marginInline: 8, height: 32, cursor: url && !active && 'pointer',
    display: 'flex', alignItems: 'center', justifyContent: 'center'
})
const labelStyle = isSelected => ({ color: isSelected ? '#FFF' : '#000', fontWeight: 'normal' })
const PaginationButton = ({ isSelected, label, onChangePage, active, url }) => {
    return (
        <div style={buttonStyle(isSelected, url, active)} onClick={() => url && !active && onChangePage(label)}>
            <h4 style={labelStyle(isSelected)}>{label}</h4>
        </div>
    )
}
const containerStyle = { marginBlock: 10 }
export default function EnhancedPagination({ list, rowsPerPage, onChangePage , title }) {
    if (rowsPerPage >= 1) {
        const pageChange = url => onChangePage(url.substring(url.indexOf('?') + 6))
        return (
            <Grid container justifyContent="flex-end" alignItems="center" style={containerStyle}>
               {list.links&& list.links.map(({ url, label, active }, i) => {
                    if (label.includes('Previous')) {
                        return <IconButton key={i} disabled={!url} onClick={() => pageChange(url)}>
                            <NavigateBeforeIcon />
                        </IconButton>
                    } else if (label.includes('Next')) {
                        return <IconButton key={i} disabled={!url} onClick={() => pageChange(url)}>
                            <NavigateNextIcon />
                        </IconButton>
                    } else {
                        return <PaginationButton key={i} isSelected={active} label={label} onChangePage={onChangePage}
                            active={active} url={url} />
                    }
                })}
            </Grid>
        )
    } return null
}