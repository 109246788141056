import React, { useState, useCallback, useEffect } from 'react'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Collections from '../components/Collections/Collections'
import { useDispatch } from 'react-redux'
import { updateCollection, resetCollection } from '../actions'
import { getCollections } from '../functions/getData'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import { showWarningSwal } from '../functions/alert'
import Loader from '../components/general/Loader'
export default function AddCollection() {
   const [loading, setLoading] = useState(false)
   document.title = 'Add Collection'
   let location = useLocation()
   const dispatch = useDispatch()
   const update = useCallback((count, head, target, text, event) => {
      return dispatch(updateCollection(count, head, target, text, event))
   }, [dispatch])
   const dupeCollection = useCallback(collection => {
      update(0, 'settingText', 'timeToSolve', collection.time_to_solve)
      update(0, 'settingText', 'initialPoints', collection.initial_points)
      update(0, 'settingMultiple', 'tags', collection.tags.map(t => t.id))
      update(0, 'settingText', 'description', collection.description)
      collection.grade_difficulty.forEach((r, i) => {
         update([0, i + 1], 'addRecommendation', 'recommendations')
         update([0, i + 1], 'recommendationText', 'grade', r.grade)
         update([0, i + 1], 'recommendationText', 'difficulty', r.difficulty)
      })
      update([0, 0], 'deleteRecommendation', 'recommendations')
      collection.sections.forEach((s, i) => {
         update(0, 'addSection', 'sections')
         s.tasks.reverse().forEach(task => {
            update([0, i + 1], 'addTaskGroup', 'taskGroups', task.map(t => ({ id: t.id, title: t.task_title })))
         })
         if (s.allow_skip) update([0, i + 1], 'sectionBoolean', 'allowSkip')
         if (s.sort_randomly) update([0, i + 1], 'sectionBoolean', 'sortRandomly')
         update([0, i + 1], 'sectionText', 'description', s.description)
      })
      update([0, 0], 'deleteSection', 'sections')
   }, [update])
   const reset = useCallback(() => dispatch(resetCollection()), [dispatch])
   useEffect(() => {
      const controller = new AbortController()
      const signal = controller.signal
      if (location.search) {
         if (location.search.slice(0, 14) !== '?duplicate_id=') return showWarningSwal('Wrong query')
         setLoading(true)
         getCollections(`?id=${location.search.slice(14)}`, signal).then(d => {
            let collection = d.collectionList.data[0]
            if (collection === undefined) return showWarningSwal('Collection not found')
            dupeCollection(collection)
         }).catch(e => console.log(e)).finally(() => {
            if (!signal.aborted) setLoading(false)
         })
      }
      return () => {
         reset()
         controller.abort()
      }
   }, [location.search, dupeCollection, reset])
   return (
      <Box className='wrapperBox'>
         <div className='firstRowContainer dashboardAndSelfBtnDiv'>
            <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
            <ChevronRightIcon />
            <ReusableButton text='Collections' fontSize={14} bgColor='#F16774' height={36} br={18} />
         </div>
         <div className='formContainer'>
            <NunitoText value='Add Collection' fontSize={40} fontWeight={700} italic color='#144A94' align='left' />
            {loading ? <Loader height={600} /> : <Collections />}
         </div>
      </Box>
   )
}