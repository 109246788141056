const competitionState = [{
   competitionId: 1,
   competitionName: '',
   alias: '',
   registrationOpen: new Date().setHours(0, 0, 0, 0),
   registrationEnd: new Date().setHours(0, 0, 0, 0),
   competitionStart: new Date(new Date().setDate(new Date().getDate() + 7)).setHours(0, 0, 0, 0),
   competitionEnd: new Date(new Date().setDate(new Date().getDate() + 14)).setHours(0, 0, 0, 0),
   format: false,
   competition_mode: '',
   tags: [],
   rerun: false,
   rerunMarkingPreference: '',
   gradesParticipating: [],
   difficultyGroup: '',
   instructions: '',
   allowPartnerEditSessions: false,
   competitionOrganizations: [],
   rounds: [{
      roundId: 1,
      roundLabel: '',
      team: false,
      contributeToIndividualScore: false,
      teamOptions: '',
      levels: [{
         levelId: 1,
         level: '',
         collection: '',
         grade: []
      }],
      awardType: false,
      assignPoints: false,
      defaultAward: '',
      defaultPoints: '',
   }]
}]
const competitionReducer = (state = competitionState, action) => {
   let newState = window.structuredClone(state)
   switch (action.type) {
      case 'DUPE_FROM_DB':
         const { name, alias, format, global_registration_date, global_registration_end_date,
            competition_start_date, competition_end_date, competition_organization,
            parent_competition_id, allowed_grades, difficulty_group_id, rounds } = action.payload
         newState = [{
            competitionId: 1,
            competitionName: name,
            alias: alias,
            registrationOpen: new Date(global_registration_date).setHours(0, 0, 0, 0),
            registrationEnd: new Date(global_registration_end_date).setHours(0, 0, 0, 0),
            competitionStart: new Date(competition_start_date).setHours(0, 0, 0, 0),
            competitionEnd: new Date(competition_end_date).setHours(0, 0, 0, 0),
            format: !!format,
            competition_mode: '',
            tags: [],
            rerun: false,
            rerunMarkingPreference: parent_competition_id ?? '',
            gradesParticipating: allowed_grades,
            difficultyGroup: difficulty_group_id,
            instructions: '',
            allowPartnerEditSessions: false,
            competitionOrganizations: competition_organization.map(org => ({
               organization_id: org.organization_id,
               country_id: org.country_id,
               allowEdit: !!org.edit_sessions,
               languagesToView: [],
               languagesToTranslate: [],
               translate: ['', '[]'].includes(org.translate) ? [] : JSON.parse(org.translate),
               type: org.competition_mode
            })),
            rounds: rounds.map((r, i) => ({
               roundId: i + 1,
               roundLabel: r.name,
               team: !!r.round_type,
               contributeToIndividualScore: !!r.individual_points,
               teamOptions: !!r.team_settings,
               levels: r.levels.map((l, j) => ({
                  levelId: j + 1,
                  level: l.name,
                  collection: l.collection_id,
                  grade: l.grades
               })),
               awardType: r.award_type,
               assignPoints: r.assign_points,
               defaultAward: r.default_award_nam,
               defaultPoints: r.default_award_points ?? '',
            }))
         }]
         return newState
      case 'ADD_COMPETITION':
         let temp = window.structuredClone(competitionState[0])
         temp.competitionId = newState.map(n => n.competitionId).reduce((a, b) => Math.max(a, b), 0) + 1
         newState.splice(action.count, 0, temp)
         return newState
      case 'DELETE_COMPETITION':
         newState.splice(action.count, 1)
         return newState
      case 'UPDATE_COMPETITION':
         switch (action.head) {
            case 'competitionBoolean':
               newState[action.count][action.target] = action.payload
               if (action.target === 'allowPartnerEditSessions') {
                  //If setting allowPartnerEditSessions, set all allowEdit as well
                  for (const c of newState[action.count].competitionOrganizations) {
                     c.allowEdit = newState[action.count][action.target]
                  }
               }
               return newState
            //Settings and general competition
            case 'settingText':
               //If changing the difficulty group, reset default difficulty
               if (['difficultyGroup'].includes(action.target)) newState[action.count].defaultDifficulty = ''
               newState[action.count][action.target] = action.payload
               return newState
            case 'settingDate':
               newState[action.count][action.target] = action.event
               return newState
            case 'settingMultiple':
               newState[action.count][action.target] = typeof action.payload === 'string' ? action.payload.split(',') : action.payload
               return newState
            //Competition Organization
            case 'setCompetitionOrganizations':
               // When duplicating from DB
               newState[action.count][action.target] = action.payload
               return newState
            case 'addCompetitionOrganization':
               newState[action.count[0]][action.target].splice(action.count[1], 0, {
                  organization_id: '',
                  country_id: '',
                  allowEdit: false,
                  languagesToView: [],
                  languagesToTranslate: [],
                  competition_mode: '',
                  translate: []
               })
               return newState
            case 'deleteCompetitionOrganization':
               newState[action.count[0]][action.target].splice(action.count[1], 1)
               return newState
            case 'competitionOrganizationBoolean':
               newState[action.count[0]].competitionOrganizations[action.count[1]][action.target]
                  = !newState[action.count[0]].competitionOrganizations[action.count[1]][action.target]
               //If all allowEdit are true or false, set allowPartnerEditSessions as well
               // Don't use for now
               // if (new Set(newState[action.count[0]].competitionOrganizations.map(c => c.allowEdit)).size === 1) {
               //     newState[action.count[0]].allowPartnerEditSessions =
               //         newState[action.count[0]].competitionOrganizations[action.count[1]][action.target]
               // }
               return newState
            case 'competitionOrganizationText':
               newState[action.count[0]].competitionOrganizations[action.count[1]][action.target] = action.payload
               if (action.target === 'organization_id') {
                  newState[action.count[0]].competitionOrganizations[action.count[1]].country_id = ''
               }
               return newState
            case 'competitionOrganizationMultiple':
               newState[action.count[0]].competitionOrganizations[action.count[1]][action.target] =
                  typeof action.payload === 'string' ? action.payload.split(',') : action.payload
               return newState
            case 'competitionOrganizationDate':
               newState[action.count[0]].competitionOrganizations[action.count[1]][action.target] = action.event
               return newState
            //Round
            case 'addRound':
               let c = window.structuredClone(competitionState[0].rounds[0])
               c.roundId = newState[action.count].rounds.map(r => r.roundId).reduce((a, b) => Math.max(a, b), 0) + 1
               newState[action.count][action.target].push(c)
               return newState
            case 'deleteRound':
               newState[action.count[0]][action.target].splice(action.count[1], 1)
               return newState
            case 'duplicateRound':
               let duplicatedRound = window.structuredClone(action.payload)
               duplicatedRound.roundId = newState[action.count[0]].rounds.map(r => r.roundId).reduce((a, b) => Math.max(a, b), 0) + 1
               duplicatedRound.roundLabel = ''
               newState[action.count[0]][action.target].splice(action.count[1], 0, duplicatedRound)
               return newState
            case 'roundBoolean':
               // if (['usePosition', 'useGradeToAssignPoints', 'useForAll'].includes(action.target)) {
               //     newState[action.count[0]].rounds[action.count[1]].awards[action.target]
               //         = !newState[action.count[0]].rounds[action.count[1]].awards[action.target]
               // } else {
               //     newState[action.count[0]].rounds[action.count[1]][action.target]
               //         = !newState[action.count[0]].rounds[action.count[1]][action.target]
               // }
               newState[action.count[0]].rounds[action.count[1]][action.target]
                  = !newState[action.count[0]].rounds[action.count[1]][action.target]
               return newState
            case 'roundText':
               newState[action.count[0]].rounds[action.count[1]][action.target] = action.payload
               return newState
            //Levels within a round
            case 'addLevel':
               let d = window.structuredClone(competitionState[0].rounds[0].levels[0])
               d.levelId = newState[action.count[0]].rounds[action.count[1]].levels.map(l => l.levelId).reduce((a, b) => Math.max(a, b), 0) + 1
               newState[action.count[0]].rounds[action.count[1]][action.target].splice(action.count[2], 0, d)
               return newState
            case 'deleteLevel':
               newState[action.count[0]].rounds[action.count[1]][action.target].splice(action.count[2], 1)
               return newState
            case 'levelText':
               newState[action.count[0]].rounds[action.count[1]].levels[action.count[2]][action.target] = action.payload
               return newState
            default: return state
         }
      case 'RESET_COMPETITIONS':
         newState = window.structuredClone(competitionState)
         return newState
      default: return state
   }
}
export default competitionReducer