import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import NunitoText from "../general/NunitoText";
import ReusableButton from "../general/ReusableButton";
import ReusableCheckbox from "../general/ReusableCheckbox";

export default function PatchMarkingOptionsModal({
  confirmFunction,
  cancelConfirm,
}) {
  const [checkAll, setCheckAll] = useState(true);
  // const [clearPrevious, setClearPrevious] = useState(false);
  const [remark, setRemark] = useState(false);
  const [checkboxes, setCheckboxes] = useState([
    {
      name: "country_rank",
      label: "Country Rank",
      checked: true,
    },
    {
      name: "school_rank",
      label: "School Rank",
      checked: true,
    },
    {
      name: "award",
      label: "Award",
      checked: true,
    },
    {
      name: "global_rank",
      label: "Global Rank",
      checked: true,
    },
  ]);

  const handleCheckboxChange = (index) => {
    // console.log(index);
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;

    // //condition to check if Global Rank is checked then make Award checked also
    // if (index === 3 && updatedCheckboxes[index].checked) {
    //   updatedCheckboxes[2].checked = true;
    // }

    // //condition to check if Award is unchecked then make Global Rank unchecked also
    // if (index === 2 && !updatedCheckboxes[index].checked) {
    //   updatedCheckboxes[3].checked = false;
    // }

    setCheckboxes(updatedCheckboxes);

    // Check if all checkboxes are checked
    const allChecked = updatedCheckboxes.every((checkbox) => checkbox.checked);
    setCheckAll(allChecked);
  };

  const handleCheckAllChange = (event) => {
    const newCheckAll = !checkAll;
    setCheckAll(newCheckAll);

    // Update the state of all checkboxes based on the newCheckAll value
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      checked: newCheckAll,
    }));
    setCheckboxes(updatedCheckboxes);
  };

  const handleConfirm = () => {
    console.log([
      {
        name: "remark",
        label: "Remark",
        checked: remark,
      },
      ...checkboxes,
    ]);
    confirmFunction(false, [
      {
        name: "remark",
        label: "Remark",
        checked: remark,
      },
      ...checkboxes,
    ]);
  };

  return (
    <Box
      className="popUpModal"
      style={{
        width: 705,
        minHeight: 310,
        position: "fixed",
        top: "50%",
        right: "50%",
        zIndex: 15,
      }}
    >
      <NunitoText
        value="Marking and Computation of results"
        fontSize={30}
        fontWeight={600}
        color="#5E75C3"
        marginTop={30}
      />
      <NunitoText
        value={`Please indicate other computational tasks required besides marking`}
        fontSize={20}
        fontWeight={600}
      />
      <Grid
        container
        spacing={2}
        columns={{ xs: 4 }}
        style={{
          margin: "30px 40px 20px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Grid
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <ReusableCheckbox
            type="modal"
            label={"Clear previous results"}
            fontSize="24px"
            state={clearPrevious}
            setState={setClearPrevious}
          />
        </Grid> */}
        {/* <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <ReusableCheckbox
            type="modal"
            label={"All"}
            fontSize="24px"
            state={checkAll}
            setState={handleCheckAllChange}
          />
        </Grid> */}
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <ReusableCheckbox
            type="modal"
            label={"Re-mark"}
            fontSize="24px"
            state={remark}
            setState={setRemark}
          />
        </Grid>
        {checkboxes.map(({ label, checked }, index) => {
          return (
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              key={index}
            >
              <ReusableCheckbox
                type="modal"
                label={label}
                fontSize="24px"
                state={checked}
                setState={() => handleCheckboxChange(index)}
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid
        container
        justifyContent="space-evenly"
        style={{ marginBlock: "30px 20px" }}
      >
        <ReusableButton
          text="Cancel"
          bgColor="#8D8D8D"
          fontSize={25}
          height={63}
          width={280}
          onClick={() => cancelConfirm()}
        />
        <ReusableButton
          text="Confirm"
          bgColor="#5E75C3"
          fontSize={25}
          height={63}
          width={280}
          onClick={handleConfirm}
        />
      </Grid>
    </Box>
  );
}
