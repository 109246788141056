const collectionState = [{
   collectionId: 1,
   competitionName: '',
   identifier: '',
   name: '',
   description: '',
   timeToSolve: '',
   initialPoints: '',
   sortRandomly: false,
   tags: [],
   languages: [],
   recommendations: [{
      grade: '',
      difficulty: ''
   }],
   sections: [{
      sectionId: 1,
      sortRandomly: false,
      allowSkip: false,
      description: '',
      tasks: []
   }]
}]
const collectionReducer = (state = collectionState, action) => {
   // let newState = state.slice()
   let newState = window.structuredClone(state)
   switch (action.type) {
      case 'ADD_COLLECTION':
         let temp = window.structuredClone(collectionState[0])
         temp.collectionId = newState.map(n => n.collectionId).reduce((a, b) => Math.max(a, b), 0) + 1
         newState.splice(action.count, 0, temp)
         console.log(newState , 'add collection')
         return newState
      case 'DELETE_COLLECTION':
         newState.splice(action.count, 1)
         return newState
      case 'DUPLICATE_COLLECTION':
         let a = window.structuredClone(action.collection)
         a.collectionId = newState.map(n => n.collectionId).reduce((a, b) => Math.max(a, b), 0) + 1
         a.identifier = ''
         newState.splice(action.count, 0, a)
         return newState
      case 'UPDATE_COLLECTION':
         switch (action.head) {
            //Settings
            case 'settingText':
               newState[action.count][action.target] = action.payload
               if (action.target === 'name') {
                  newState[action.count].identifier = action.payload.toLowerCase().trim().replace(/\s/g, '_')
               }
               return newState
            case 'settingMultiple':
               newState[action.count][action.target] = typeof action.payload === 'string' ? action.payload.split(',') : action.payload
               return newState
            //Recommendations
            case 'addRecommendation':
               newState[action.count[0]][action.target].splice(action.count[1], 0, { grade: '', difficulty: '' })
               return newState
            case 'deleteRecommendation':
               newState[action.count[0]][action.target].splice(action.count[1], 1)
               return newState
            case 'recommendationText':
               newState[action.count[0]].recommendations[action.count[1]][action.target] = action.payload
               return newState
            //Sections
            case 'addSection':
               let b = window.structuredClone(collectionState[0].sections[0])
               b.sectionId = newState[action.count].sections.map(s => s.sectionId).reduce((a, b) => Math.max(a, b), 0) + 1
               newState[action.count][action.target].push(b)
               return newState
            case 'deleteSection':
               newState[action.count[0]][action.target].splice(action.count[1], 1)
               return newState
            case 'sectionBoolean':
               newState[action.count[0]].sections[action.count[1]][action.target] =
                  !newState[action.count[0]].sections[action.count[1]][action.target]
               return newState
            case 'sectionText':
               newState[action.count[0]].sections[action.count[1]][action.target] = action.payload
               return newState
            //Task Group and Tasks
            case 'addTaskGroup':
               //Splice in an array of task
               // let c = action.payload.map(task => ({ id: task.id, task_title: task.title }))
               action.payload.reverse().forEach(task => {
                  newState[action.count[0]].sections[action.count[1]][action.target].splice(action.count[2], 0, [{ id: task.id, task_title: task.title }])
               })
               // newState[action.count[0]].sections[action.count[1]][action.target].splice(action.count[2], 0, c)
               return newState
            case 'deleteTaskGroup':
               //Splice away a task group
               newState[action.count[0]].sections[action.count[1]][action.target].splice(action.count[2], 1)
               return newState
            case 'addTaskToGroup':
               //Splice in task(s) to an array of task
               action.payload.reverse().forEach(task => {
                  let obj = { id: task.id, task_title: task.title }
                  newState[action.count[0]].sections[action.count[1]][action.target][action.count[2]]
                     .splice(action.count[3], 0, obj)
               })
               return newState
            case 'deleteTaskFromGroup':
               // Splice away a task from a task group
               newState[action.count[0]].sections[action.count[1]].tasks[action.count[2]].splice(action.count[3], 1)
               return newState
            case 'sortTaskGroup':
               [
                  newState[action.count[0]].sections[action.count[1]][action.target][Number(action.event.oldIndex)],
                  newState[action.count[0]].sections[action.count[1]][action.target][Number(action.event.newIndex)]
               ] =
                  [
                     newState[action.count[0]].sections[action.count[1]][action.target][Number(action.event.newIndex)],
                     newState[action.count[0]].sections[action.count[1]][action.target][Number(action.event.oldIndex)]
                  ]
               return newState
            case 'sortTask':
               [
                  newState[action.count[0]].sections[action.count[1]][action.target][action.count[2]]
                  [Number(action.event.oldIndex)],
                  newState[action.count[0]].sections[action.count[1]][action.target][action.count[2]]
                  [Number(action.event.newIndex)]
               ] =
                  [
                     newState[action.count[0]].sections[action.count[1]][action.target][action.count[2]]
                     [Number(action.event.newIndex)],
                     newState[action.count[0]].sections[action.count[1]][action.target][action.count[2]]
                     [Number(action.event.oldIndex)]
                  ]
               return newState
            case 'justRemoveTask':
               for (let i = 0; i < newState[action.count].sections.length; i++) {
                  const section = newState[action.count].sections[i]
                  for (let j = 0; j < section.tasks.length; j++) {
                     newState[action.count].sections[i].tasks[j] =
                        newState[action.count].sections[i].tasks[j].filter(task => !action.payload.includes(task.id))
                  }
                  newState[action.count].sections[i].tasks =
                     newState[action.count].sections[i].tasks.filter(tasks => tasks.length)
               }
               return newState
            default: return state
         }
      case 'RESET_COLLECTIONS':
         newState = window.structuredClone(collectionState)
         return newState
      default: return state
   }
}
export default collectionReducer