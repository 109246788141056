import { functionError, showWarningSwal } from "./alert";
import store from "../reducers/store";
import {
  userLogout,
  setLanguageOptions,
  setRoleOptions,
  setCountryOptions,
} from "../actions/index";
const headers = {
  "Content-type": "application/json",
  Accept: "application/json",
};
// Set Content-type and Accept in headers now, later when setting options in fetch request then set Authorization by
// doing { headers: {...headers, Authorization: `Bearer ${store.getState().user.token}` } }
// I'm setting the Authorization in headers this way because if I defined it in the headers object above,
// it would use the outdated user.token for the first API call after logging in, which will result in unauthenticated
// const url = 'http://192.168.1.3/laravel-sanctum/public/api'
// const url = 'https://cms.adstarks.com/laravel/public/api'
// Dev
// const url = "https://api.dev.simccms.org/api";
// //sandbox
const url = "https://api.sandbox-4.simccms.org/api";
// Staging
// const url = "https://api.stag.simccms.org/api";
// Debug
// const url = "https://api.debug.simccms.org/api";
// Production
// const url = "https://api.simccms.org/api";
const checkResponse = (res) => {
  if (res.status === 401) {
    console.log("Unauthenticated");
    store.dispatch(userLogout());
    store.dispatch(setLanguageOptions([]));
    store.dispatch(setCountryOptions([]));
    store.dispatch(setRoleOptions([]));
    return;
  }
};
const invalidStatus = (data, status) => {
  // console.log(`data.status: ${data.status}, res.status: ${status}`)
  if (![200, 201, 206, 202].includes(Number(status))) {
    if (data.errors === undefined) {
      showWarningSwal(`Error ${data.status || status}: ${data.message}`);
    } else {
      let msg = "";
      Object.values(data.errors).forEach((value) => (msg += `<br>${value}`));
      showWarningSwal(`Error ${data.status || status}: ${msg}`);
    }
    return true;
  }
  return false;
};
export const getMethod = (path, params, signal) => {
  console.log(`${url}/${path}${params}`);
  let status;
  let options = {
    headers: {
      ...headers,
      Authorization: `Bearer ${store.getState().user.token}`,
    },
  };
  if (signal !== undefined) options.signal = signal;
  return new Promise((resolve, reject) => {
    fetch(`${url}/${path}${params}`, options)
      .then((res) => {
        checkResponse(res);
        status = res.status;
        return res.json();
      })
      .then((data) => {
        if (invalidStatus(data, status)) {
          return resolve([]);
        }
        resolve(data.data);
      })
      .catch((error) => {
        if (error.name === "AbortError") console.log("Aborted Successfully");
        else {
          console.log(error);
          functionError(`Error at getMethod ${path}: ${error.message}`);
        }
        reject(error);
      });
  });
};
export const getMethod2 = (path, params, signal) => {
  let status;
  let options = {
    headers: {
      ...headers,
      Authorization: `Bearer ${store.getState().user.token}`,
    },
  };
  console.log(`${url}/${path}${params}`);
  if (signal !== undefined) options.signal = signal;
  return new Promise((resolve, reject) => {
    fetch(`${url}/${path}${params}`, options)
      .then((res) => {
        checkResponse(res);
        status = res.status;
        return res.json();
      })
      .then((data) => {
        if (invalidStatus(data, status)) {
          return resolve([]);
        }

        resolve(data);
      })
      .catch((error) => {
        if (error.name === "AbortError") console.log("Aborted Successfully");
        else {
          console.log(error);
          functionError(`Error at getMethod ${path}: ${error.message}`);
        }
        reject(error);
      });
  });
};
export const downloadingfiles = (path, fileNmae) => {
  const headers = {};
  let options = {
    headers: {
      ...headers,
      Authorization: `Bearer ${store.getState().user.token}`,
    },
  };
  return new Promise((resolve, reject) => {
    fetch(`${url}/${path}`, options)
      .then((res) => {
        console.log(res);
        return res.blob();
      })
      .then((data) => {
        console.log(path);
        console.log(data);
        resolve(data);
        const urll = window.URL.createObjectURL(data);

        // Create a link element
        const link = document.createElement("a");

        // Set the link's href to the URL of the Blob
        link.href = urll;

        // Set the download attribute with the desired file name
        link.download = `${fileNmae}.xlsx`;

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger a click on the link to start the download
        link.click();

        // Remove the link from the document body
        document.body.removeChild(link);
      })
      .catch((error) => {
        if (error.name === "AbortError") console.log("Aborted Successfully");
        else {
          console.log(error);
          functionError(`Error at getMethod ${path}: ${error.message}`);
        }
        reject(error);
      });
  });
};
export const getMethod3 = (path, params, body, signal) => {
  let status;
  let options = {
    headers: {
      ...headers,
      Authorization: `Bearer ${store.getState().user.token}`,
    },
  };
  console.log(`${url}/${path}${params}`);
  if (signal !== undefined) options.signal = signal;
  return new Promise((resolve, reject) => {
    fetch(`${url}/${path}${params}`, options)
      .then((res) => {
        checkResponse(res);
        console.log(res);
        status = res.status;
        return res.json();
      })
      .then((data) => {
        if (invalidStatus(data, status)) {
          return resolve([]);
        }

        resolve(data);
      })
      .catch((error) => {
        if (error.name === "AbortError") console.log("Aborted Successfully");
        else {
          console.log(error);
          functionError(`Error at getMethod ${path}: ${error.message}`);
        }
        reject(error);
      });
  });
};
export const postMethod = (path, data, signal) => {
  let status;
  let options = {
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${store.getState().user.token}`,
    },
    body: JSON.stringify(data),
  };
  console.log(data);
  if (signal !== undefined) options.signal = signal;
  return new Promise((resolve, reject) => {
    fetch(`${url}/${path}`, options)
      .then((res) => {
        checkResponse(res);
        status = res.status;
        return res.json();
      })
      .then((data) => {
        invalidStatus(data, status);
        console.log(data);
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        if (error.name === "AbortError")
          console.log("Post Aborted Successfully");
        else {
          console.log(error);
          functionError(`Error at postMethod ${path}: ${error.message}`);
        }
        reject(error);
      });
  });
};
export const patchMethod = (path, data, params) => {
  let status;
  let finalPath = `${url}/${path}`;
  if (params !== undefined) finalPath = `${url}/${path}/${params}`;
  let options = {
    method: "PATCH",
    headers: {
      ...headers,
      Authorization: `Bearer ${store.getState().user.token}`,
    },
  };
  if (data !== undefined) options.body = JSON.stringify(data);
  return new Promise((resolve, reject) => {
    fetch(finalPath, options)
      .then((res) => {
        checkResponse(res);
        status = res.status;
        return res.json();
      })
      .then((data) => {
        invalidStatus(data, status);
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        functionError(`Error at patchMethod ${path}: ${error.message}`);
        reject(error);
      });
  });
};
export const putMethod = (path, data) => {
  return new Promise((resolve, reject) => {
    fetch(`${url}/${path}`, {
      method: "PUT",
      headers: {
        ...headers,
        Authorization: `Bearer ${store.getState().user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        checkResponse(res);
        return res.json();
      })
      .then((data) => resolve(data))
      .catch((error) => {
        console.log(error);
        functionError(`Error at putMethod ${path}: ${error.message}`);
        reject(error);
      });
  });
};
export const deleteMethod = (path, data) => {
  console.log(data);
  let status;
  return new Promise((resolve, reject) => {
    console.log(path, data);
    fetch(`${url}/${path}`, {
      method: "DELETE",
      headers: {
        ...headers,
        Authorization: `Bearer ${store.getState().user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        checkResponse(res);
        status = res.status;
        return res.json();
      })
      .then((data) => {
        invalidStatus(data, status);
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        functionError(`Error at deleteMethod ${path}: ${error.message}`);
        reject(error);
      });
  });
};
export const login = (data) => {
  return new Promise((resolve, reject) => {
    fetch(`${url}/login`, {
      method: "POST",
      headers,
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((error) => {
        console.log(error);
        functionError(`Error at login: ${error.message}`);
        reject(error);
      });
  });
};
export const logout = () => {
  return new Promise((resolve, reject) => {
    fetch(`${url}/user/logout`, {
      headers: {
        ...headers,
        Authorization: `Bearer ${store.getState().user.token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((error) => {
        console.log(error);
        functionError(`Error at logout: ${error.message}`);
        reject(error);
      });
  });
};
