import React, { useState, useEffect, useRef } from "react";
import { Box, Grid } from "@mui/material";
import {
  makeSureIsArray,
  gradeOptions as allGradeOptions,
  invalidName,
} from "../../functions/general";
import { showWarningSwal } from "../../functions/alert";
import { getCompetitions, getSchools } from "../../functions/getData";
import { patchParticipant } from "../../functions/patchData";
import NunitoText from "../general/NunitoText";
import ReusableTextField from "../general/ReusableTextField";
import ReusableAutocompleteWithID from "../general/ReusableAutocompleteWithID";
import ReusableButton from "../general/ReusableButton";
import { isAdminOrPartnerOrAssistant } from "../../functions/checkrole";
const boxStyle = { width: 1200, minHeight: 400, maxHeight: "90%" };
const firstRowStyle = { marginTop: 20, marginBottom: 30 };
const rowStyle = { marginBottom: 30 };
const buttonsContainerStyle = { marginBottom: 30, paddingInline: 100 };
export default function EditParticipant({
  setLoading,
  table,
  id,
  afterEditingParticipant,
  cancelEdit,
}) {
  const isMounted = useRef(false);
  const typeOptions = [
    { id: -1, name: "Private Candidate" },
    { id: 1, name: "Organization Candidate" },
  ];
  const editingParticipant = () => {
    let obj = makeSureIsArray(table.data).find((p) => p.id === id);
    obj.school_id = obj.school_id || -1;
    obj.tuition_centre_id =
      obj.tuition_centre_name === "Organization School"
        ? ""
        : obj.tuition_centre_id || "";
    obj.class = obj.class || "";
    obj.email = obj.email || "";
    return obj;
  };
  const [participant, setParticipant] = useState(editingParticipant());
  const [identifier, setIdentifier] = useState();
  const {
    name,
    grade,
    competition_id,
    competition_name,
    country_name,
    school_name,
    tuition_centre_name,
    organization_name,
    country_id,
    school_id,
    tuition_centre_id,
    email,
  } = participant;
  const [schoolOptions, setSchoolOptions] = useState([
    { id: school_id, name: school_name },
  ]);
  // const [editedemail , setEmail] = useState(email)
  const [tuitionCentreOptions, setTuitionCentreOptions] = useState([
    { id: tuition_centre_id, name: tuition_centre_name },
  ]);
  const [gradeOptions, setGradeOptions] = useState(allGradeOptions);
  const initialType = participant.private
    ? tuition_centre_name === "Organization School"
      ? 1
      : -1
    : 0;
  const [type, setType] = useState(
    participant.private
      ? tuition_centre_name === "Organization School"
        ? 1
        : -1
      : 0
  );
  const updateParticipant = (identifier, value) => {
    let newParticipant = { ...participant };
    newParticipant[identifier] = value;
    setParticipant(newParticipant);
  };
  const unchanged = () => {
    let og = editingParticipant();
    if (initialType === 0)
      return JSON.stringify(og) === JSON.stringify(participant);
    return (
      JSON.stringify(og) === JSON.stringify(participant) && initialType === type
    );
  };
  const onSubmit = () => {
    if (unchanged())
      return showWarningSwal("Please make some changes before submitting");
    let msg = "";
    // if (invalidName(name)) msg += 'Participant name is invalid.<br>'
    if (name === "") msg += "Participant name is invalid.<br>";
    if (grade === "") msg += "Please select a grade.<br>";
    if (invalidName(participant.class) && participant.class.length)
      msg += "Class name is invalid.<br>";
    if (isAdminOrPartnerOrAssistant()) {
      if (school_id === "") msg += "Please select a school.<br>";
      if (type === -1 && tuition_centre_id === "")
        msg += `Please select a tuition centre if participant is Private Candidate.<br>`;
      if (type === 0 && school_id === -1) {
        msg +=
          "Please select an actual school(Meaning not Home School) if participant is a School Candidate.<br>";
      }
    }
    if (msg) return showWarningSwal(msg);
    setLoading(true);
    let payload = {
      id,
      name: name.toUpperCase(),
      grade,
      class: participant.class,
      email: participant.email,
      school_id: ["", -1].includes(school_id) ? null : school_id,
      tuition_centre_id: [1].includes(type) ? null : tuition_centre_id,
      for_partner: [1].includes(type) ? 1 : 0,
    };
    console.log(payload);
    patchParticipant(payload)
      .then((d) => {
        isMounted.current && setLoading(false);
        afterEditingParticipant(cancelEdit, d);
      })
      .catch((e) => console.log(e))
      .finally(() => isMounted.current && setLoading(false));
  };
  const actualSchoolOptions = [
    [-1, 1].includes(type) && { id: -1, name: "Home School" },
    ...schoolOptions,
  ].filter(Boolean);
  useEffect(() => {
    isMounted.current = true;
    const controller = new AbortController();
    const signal = controller.signal;
    getCompetitions(`/${competition_id}`, signal)
      .then((d) => {
        setGradeOptions(
          allGradeOptions.filter((g) =>
            d.competitionList.data[0].allowed_grades
              .map((z) => Number(z))
              .includes(g.id)
          )
        );
      })
      .catch((e) => console.log(e));
    getSchools(`?limits=0&&country_id=${country_id}`, signal)
      .then((s) => {
        setSchoolOptions(
          s.SchoolLists.data
            .filter((d) => !d.private)
            .map((o) => ({ id: o.id, name: o.name }))
        );
        setTuitionCentreOptions(
          s.SchoolLists.data
            .filter((d) => d.private)
            .map((o) => ({ id: o.id, name: o.name }))
        );
      })
      .catch((e) => console.log(e));
    return () => {
      controller.abort();
      isMounted.current = false;
    };
  }, [competition_id, country_id]);
  return (
    <Box className="popUpModal horizontalScrollable" style={boxStyle}>
      <div>
        <NunitoText
          value="EDIT PARTICIPANT"
          fontSize={30}
          fontWeight={600}
          color="#5E75C3"
          marginTop={20}
        />
        {isAdminOrPartnerOrAssistant() && Boolean(participant.private) && (
          <Grid container justifyContent="center">
            <ReusableAutocompleteWithID
              type="withLabel"
              width={400}
              height={48}
              placeholder="Type*"
              state={type}
              setState={setType}
              borderColor="#707070"
              required
              options={typeOptions.map((t) => ({ id: t.id, option: t.name }))}
            />
          </Grid>
        )}
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          style={firstRowStyle}
        >
          <ReusableTextField
            type="withLabel"
            width={550}
            bgColor="#F2F2F2"
            placeholder="Name*"
            state={name}
            target="name"
            setState={updateParticipant}
            required
          />
          <ReusableTextField
            type="withLabel"
            width={550}
            bgColor="#F2F2F2"
            placeholder="Class"
            state={participant.class}
            target="class"
            setState={updateParticipant}
          />
        </Grid>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          style={rowStyle}
        >
          <ReusableAutocompleteWithID
            type="withLabel"
            width={550}
            placeholder="Grade*"
            state={grade}
            target="grade"
            setState={updateParticipant}
            borderColor="#707070"
            required
            options={gradeOptions.map((g) => ({ id: g.id, option: g.name }))}
          />
          <ReusableAutocompleteWithID
            type="withLabel"
            width={550}
            placeholder="Competition*"
            state={1}
            borderColor="#707070"
            required
            options={[{ id: 1, option: competition_name }]}
            grayedOut
            readOnly
          />
        </Grid>
        <Grid
          container
          justifyContent="space-evenly"
          alignItems="center"
          style={rowStyle}
        >
          <ReusableAutocompleteWithID
            type="withLabel"
            width={550}
            placeholder="Organization*"
            state={1}
            borderColor="#707070"
            required
            options={[{ id: 1, option: organization_name }]}
            grayedOut
            readOnly
          />
          <ReusableAutocompleteWithID
            type="withLabel"
            width={550}
            placeholder="Country*"
            state={1}
            borderColor="#707070"
            required
            options={[{ id: 1, option: country_name }]}
            grayedOut
            readOnly
          />
          {/* <ReusableAutocompleteWithID type='withLabel' width={550}  placeholder='Partner*'
                        state={1}  borderColor='#707070' required
                        options={[{ id: 1, option: partner_name }]} grayedOut readOnly /> */}
        </Grid>
        {isAdminOrPartnerOrAssistant() && (
          <>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              style={rowStyle}
            >
              <ReusableAutocompleteWithID
                type="withLabel"
                width={550}
                placeholder="School*"
                state={school_id}
                target="school_id"
                setState={updateParticipant}
                borderColor="#707070"
                required
                options={actualSchoolOptions.map((s) => ({
                  id: s.id,
                  option: s.name,
                }))}
              />
              <ReusableAutocompleteWithID
                type="withLabel"
                width={550}
                placeholder="Tuition Centre*"
                state={tuition_centre_id}
                target="tuition_centre_id"
                setState={updateParticipant}
                borderColor="#707070"
                required
                options={tuitionCentreOptions.map((t) => ({
                  id: t.id,
                  option: t.name,
                }))}
                hide={[0, 1].includes(type)}
              />
            </Grid>
            <Grid container justifyContent="center">
              <ReusableTextField
                width={550}
                height={60}
                marginRight={"3%"}
                placeholder={"Email"}
                type="withLabel"
                bgColor="#F2F2F2"
                target={"email"}
                marginBottom={"2%"}
                state={email}
                setState={updateParticipant}
              />
              <ReusableTextField
                type="withLabel"
                width={550}
                height={60}
                bgColor="#F2F2F2"
                placeholder="identifier*"
                state={identifier}
                setState={setIdentifier}
              />
            </Grid>
          </>
        )}
      </div>
      <Grid
        container
        justifyContent="space-evenly"
        style={buttonsContainerStyle}
      >
        <ReusableButton
          text="Cancel"
          fontSize={22}
          width={300}
          bgColor="#8D8D8D"
          onClick={() => cancelEdit()}
        />
        <ReusableButton
          text="Submit"
          fontSize={22}
          width={300}
          bgColor="#5E75C3"
          onClick={() => onSubmit()}
        />
      </Grid>
    </Box>
  );
}
