import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Tabs, Tab } from '@mui/material'
import { makeSureIsArray } from '../../functions/general'
import { showWarningSwal, showInfoSwal } from '../../functions/alert'
import { getOrganizations } from '../../functions/getData'
import { addCompetitionOrganization } from '../../functions/postData'
import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import NunitoText from '../general/NunitoText'
import ReusableToggle from '../general/ReusableToggle'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
const startColumnFlexStyle = { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }
const centerFlexStyle = { display: 'flex', alignItems: 'center' }
function EachForm({ forms, setForms, count, competition, organizationOptions }) {
   const { organization, country, translate, edit_sessions } = forms[count]
   const allCountryOptions = useSelector(state => state.countryOptions)
   const languageOptions = useSelector(state => state.languageOptions)
   const updateForm = (identifier, value) => {
      let newForms = [...forms]
      newForms[count][identifier] = value
      if (identifier === 'organization') newForms[count].country = ''
      setForms(newForms)
   }
   let filteredOrgUsers = users => Array.isArray(users) ? users.filter(u => u.role_id === 2 && u.status === 'active') : []
   let countries = [...new Set(filteredOrgUsers(organizationOptions.find(o => o.id === organization)?.users).map(u => u.country_id))]
   let takenCountries = forms.filter((p, i) => i !== count).map(f =>
      ({ organization_id: f.organization, country_id: f.country }))
   let alreadyJoinedCountries = competition.competition_organization.map(o =>
      ({ organization_id: o.organization_id, country_id: o.country_id }))
   let cannotJoin = [takenCountries, alreadyJoinedCountries].flat().filter((value, i, self) =>
      i === self.findIndex(t => t.organization_id === value.organization_id && t.country_id === value.country_id)
   )
   let filteredCountryOptions = allCountryOptions.filter(c => countries.includes(c.id)
      && (!cannotJoin.find(t => t.organization_id === organization && t.country_id === c.id) || c.id === country))
   const containerStyle = { padding: 40 }
   // const radioStyle = { color: '#000' }
   // const competitionModeWrapperStyle = { display: 'flex', alignItems: 'center', marginTop: 20 }
   console.log(filteredCountryOptions ,allCountryOptions , countries )

   return (
      <Grid container alignItems='center' justifyContent='space-between' style={containerStyle}>
         <ReusableAutocompleteWithID type='withLabel' width={280} placeholder='Organization*' state={organization}
            setState={updateForm} target='organization' borderColor='#707070' required
            options={organizationOptions.map(o => ({ id: o.id, option: o.name }))} />
         <ReusableAutocompleteWithID type='withLabel' width={280} placeholder='Country*' state={country}
            setState={updateForm} target='country' borderColor='#707070' required
            options={filteredCountryOptions.map(r => ({ id: r.id, option: r.display_name }))}
            grayedOut={!organization} readOnly={!organization} />
         <ReusableAutocompleteWithID type='withLabel' width={460} placeholder='Translation' state={translate}
            setState={updateForm} multiple target='translate' borderColor='#707070'
            options={languageOptions.map(l => ({ id: l.id, option: l.name }))} />
         {/* <div style={competitionModeWrapperStyle}>
                <NunitoText value='Mode of Competition:' fontSize={18} fontWeight={600} italic />
                <RadioGroup value={competition_mode} style={{ marginLeft: 20 }} onChange={e => updateForm('competition_mode', e.target.value)}>
                    <Grid container>
                        <FormControlLabel value='0' control={<Radio style={radioStyle} />} label="Paper Based" />
                        <FormControlLabel value='1' control={<Radio style={radioStyle} />} label="Online" />
                        <FormControlLabel value='2' control={<Radio style={radioStyle} />} label="Both" />
                    </Grid>
                </RadioGroup>
                <ReusableButton text='Clear Selection' color='#000' borderColor='#000' fontSize={16} marginRight={20}
                    onClick={() => updateForm('competition_mode', '')} />
            </div> */}
         <div style={startColumnFlexStyle}>
            <NunitoText value='Allow Session Edits By Partners' fontSize={16} fontWeight={600} italic />
            <ReusableToggle type='text' width={160} falseValue='No' trueValue='Yes'
               state={edit_sessions} setState={updateForm} target='edit_sessions' />
         </div>
      </Grid>
   )
}
const boxStyle = { width: 1400, minHeight: 400, maxHeight: '90%' }
const addCountryButtonDivStyle = { display: 'flex', justifyContent: 'flex-end', marginTop: -45, marginRight: 20 }
const divStyle = {
   borderRadius: 10, margin: '20px 20px 0 20px',
   boxShadow: '0px 1px 6px rgb(0,0,0,0.2), 0 1px 6px rgb(0,0,0,0.2)'
}
const tabPropsStyle = { height: 0 }
const tabStyle = (value, i) => ({
   color: value !== i && '#707070',
   backgroundColor: value !== i && '#E7E6E6',
   border: value === i && '1px solid #F0F0F0',
   boxShadow: value === i && '0px 1px 6px rgb(0,0,0,0.2), 0px 1px 6px rgb(0,0,0,0.2)',
   borderRadius: '10px 10px 0 0',
   textTransform: 'none',
   marginRight: 10, height: 40
})
const iconStyle = { position: 'relative', left: 12, height: 18, width: 18, color: '#707070' }
const buttonsContainerStyle = { paddingInline: 60, marginBlock: 30 }
export default function AddParticipatingCountryModal({ setLoading, table, id,
   cancelAddCompetitionOrganization, afterAddingCompetitionOrganization }) {
   const isMounted = useRef(false)
   const [forms, setForms] = useState([{
      id: 1, organization: '', country: '', edit_sessions: false, competition_mode: '0', translate: []
   }])
   const [value, setValue] = useState(0)
   const [organizationOptions, setOrganizationOptions] = useState([])
   const competition = makeSureIsArray(table.data).find(c => c.id === id)
   const addForm = () => {
      if (forms.length >= 12) return showInfoSwal('Maximum number of tab is 12')
      setForms([...forms, {
         id: forms.map(f => f.id).reduce((a, b) => Math.max(a, b), 0) + 1,
         organization: '', country: '', edit_sessions: false, competition_mode: '0', translate: []
      }])
      setValue(forms.length)
   }
   const deleteForm = (e, id) => {
      e.stopPropagation()
      let newForms = forms.filter(f => f.id !== id)
      if (!newForms.length) return cancelAddCompetitionOrganization()
      let index = forms.findIndex(f => f.id === id)
      if (value === forms.length - 1 || index < value) setValue(value - 1)
      setForms(newForms)
   }
   const handleChange = (e, newValue) => setValue(newValue)
   const onSubmit = () => {
      let payload = { competition_id: id, organizations: [] }
      let msg = ''
      for (let i = 0; i < forms.length; i++) {
         const { organization, country, edit_sessions, competition_mode, translate } = forms[i]
         let warningMessage = ''
         if (organization === '') warningMessage += 'Please select organization.<br>'
         if (country === '') warningMessage += 'Please select country.<br>'
         if (!warningMessage) {
            payload.organizations.push({
               organization_id: organization,
               country_id: country,
               edit_sessions: Number(edit_sessions),
               competition_mode: Number(competition_mode),
               translate: JSON.stringify(translate)
            })
         } else {
            msg += '<div>'
            msg += `<div style="text-align:center; font-weight: 800">Error in Organization ${i + 1}</div>`
            msg += `<div style="text-align:left">${warningMessage}</div>`
            msg += '</div>'
         }
      }
      if (msg) return showWarningSwal(msg)
      setLoading(true)
      console.log(JSON.stringify(payload))
      addCompetitionOrganization(payload).then(d => {
         isMounted.current && setLoading(false)
         afterAddingCompetitionOrganization(
            cancelAddCompetitionOrganization,
            d,
            payload.organizations.map(d =>
               ({ ...d, organization_name: organizationOptions.find(o => o.id === d.organization_id).name })
            )
         )
      }).catch(e => console.log(e)).finally(() => isMounted.current && setLoading(false))
   }
   
   const controller = useMemo(() => new AbortController(), [])
   const signal = controller.signal
   useEffect(() => {
      isMounted.current = true
      getOrganizations('?limits=100&status=active', signal)
         .then(u => setOrganizationOptions(u.OrganizationLists.data.map(d => ({ id: d.id, name: d.name, users: d.users }))))
         .catch(e => console.log(e))
      return () => {
         controller.abort()
         isMounted.current = false
      }
   }, [controller, signal])
   return (
      <Box className='popUpModal horizontalScrollable' style={boxStyle}>
         <div>
            <NunitoText value={`ADD PARTICIPATING COUNTRIES`} fontSize={30} fontWeight={600} color='#5E75C3'
               marginTop={20} />
            <div style={addCountryButtonDivStyle}>
               <ReusableButton text='Add another participating country' fontSize={15} height={40} bgColor='#5E75C3'
                  iconType='add' onClick={() => addForm()} allowSpam />
            </div>
         </div>
         <div style={divStyle}>
            <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: tabPropsStyle }}
               variant="scrollable" scrollButtons>
               {forms.map((f, i) => <Tab key={f.id} label={<div style={centerFlexStyle}>
                  Country {i + 1}
                  <CloseIcon style={iconStyle} onClick={e => deleteForm(e, f.id)} />
               </div>} style={tabStyle(value, i)} />)}
            </Tabs>
            <EachForm forms={forms} setForms={setForms} count={value}
               competition={competition} organizationOptions={organizationOptions} />
         </div>
         <Grid container justifyContent='space-evenly' style={buttonsContainerStyle}>
            <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
               onClick={() => cancelAddCompetitionOrganization()} />
            <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
               onClick={() => onSubmit()} />
         </Grid>
      </Box>
   )
}