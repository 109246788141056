import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import ApproveModal from '../components/modal/ApproveModal'
import { useSelector } from 'react-redux'
import {
    makeSureIsArray, makeOptions, checkIfAllSelectedRowsMatchStatus,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction, resetAll
    
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getCollections } from '../functions/getData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import LoadingBackdrop from '../components/general/LoadingBackdrop'
import NoData from '../components/general/NoData'
import Loader from '../components/general/Loader'
import ManagePermission from '../components/Collections/ManagePermissionsModal'
import { deleteCollection } from '../functions/deleteData'
import VerfiyCollectionModal from '../components/modal/VerfiyCollectionModal'
import { verfiycollection } from '../functions/postData'
import { showNotification } from '../functions/snackbar'
import { duplicateCollections } from '../functions/postData'
import DuplicateTask from '../components/modal/DuplicateTask'
import { useSnackbar } from 'notistack'
const collectionHeader = [
    { id: 'identifier', label: 'Collection Identifier' },
    { id: 'name', label: 'Collection Name' },
    { id: 'status', label: 'Status' },
    { id: 'description', label: 'Description' },
    { id: 'tags', label: 'Tags' },
    { id: 'grade_difficulty', label: 'Recommended Difficulty' },
    { id: 'sections', label: 'No. of section' },
    // { id: 'noOfQuestions', label: 'No. of questions' },
    { id: 'initial_points', label: 'Initial Points' },
    { id: 'time_to_solve', label: 'Time to solve' },
    { id: 'competitions', label: 'Competition' },
    // { id: 'permission', label: 'Permission to Verify' },
    { id: 'created_by', label: 'Created By' },
    { id: 'last_modified_by', label: 'Last Modified By' },
    // { id: 'lastVerifiedBy', label: 'Last Verified By' },
]
const initialParams = '?limits=10'
export default function Collection() {
    document.title = 'Collection'
    const isMounted = useRef(false)
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(collectionHeader)
    const [collectionTable, setCollectionTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "Tags", state: [], key: 'tag_id', header: 'tags' },
        { label: 'Competition', state: '', key: 'competition_id', header: 'competition' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Tags", state: true, key: 'private' },
        { label: "Recommended Difficulty", state: true, key: 'address' },
        { label: "No. of Sections", state: true, key: 'postal' },
        { label: "No. of Questions", state: true, key: 'province' },
        { label: "Initial Points", state: true, key: 'phone' },
        { label: "Time to Solve", state: true, key: 'status' },
        { label: "Competition", state: true, key: 'created_by_username' },
        { label: 'Permission to Verify', state: true, key: 'approved_by_username' },
        { label: 'Created By', state: true, key: 'rejected_by_username' },
        { label: 'Last Modified By', state: true, key: 'modified_by_username' },
        { label: 'Last Verified By', state: true, key: 'reject_reason' },
        { label: "Status", state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [search, setSearch] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [loadingTable, setLoadingTable] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [onDup , setOnDups] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [id, setId] = useState('')
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onApprove, setOnApprove] = useState(false)
    const [verfiy, setOnVerfiy] = useState(false)
    const [onManagePermission , setOnManagePermission] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setId)
    const cancelApprove = () => cancelAction(setOnApprove, setId)
    const cancelVerfiy = ()=> cancelAction(setOnVerfiy , setId)
    const cancelPermission = ()=>cancelAction(setOnManagePermission , setId)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const afterFunction = (cancel, data) => {
        if ([200, 201].includes(data.status)) {
            resetAll(initialParams, filtered, setParams, setSearch, setFiltered)
            getData(initialParams, signal)
            cancel()
            showNotification('success', data.message, enqueueSnackbar, closeSnackbar)
            setSelected([])
            setSelecting([])
        }
    }
    const canceldubs = ()=> cancelAction(setOnDups)
   
    const deleteFunction = () => {
        console.log({ id: id === '' ? selected : [id] })
        setLoading(true)
        deleteCollection({ id: id === '' ? selected : [id] }).then(d => {
            getData(params)
            cancelDelete()
            setLoading(false)
        })
    }
    const approveFunction = () => {
        setLoading(true)
    }
    const getData = (params, signal) => {
        setSelected([])
        setSelecting([])
        loadData(setLoadingTable, setFirstLoaded, getCollections, params, setCollectionTable, setFilterOptions, false, signal)
    }
    const onChangeViewOptions = value => changeViewOptions(collectionHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(value, rowsPerPage, filtered, search, signal, setRowsPerPage, setParams, getData)
    }
    const onChangeFiltered = value => {
        changeFiltered(value, filtered, search, rowsPerPage, signal, setFiltered, setParams, getData)
    }
    const onChangeSearch = value => {
        changeSearch(value, search, filtered, rowsPerPage, signal, setSearch, setParams, getData)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`, signal)
    const controller = useMemo(() => new AbortController(), [])
    const signal = controller.signal
    useEffect(() => {
        isMounted.current = true
        getData(initialParams, signal)
        return () => {
            controller.abort()
            isMounted.current = false
        }
    }, [controller, signal])
    const onClickAddToCompetition = () => {
        // return selected.length ? {} :
        //     showWarningSwal('Please select at least one collection to add to competition')
    }
    const onClickMassApprove = () => {
        return collectionTable && checkIfAllSelectedRowsMatchStatus(selected, collectionTable.data, ['pending'], user) ?
            setOnApprove(true) : showWarningSwal('Please only select pending collections that are not added by you to mass approve')
    }
    const onClickMassDelete = () => {
        return selected.length ? setOnDelete(true) : showWarningSwal('Please select at least one collection to mass delete')
    }
    const duplicate = ()=>{
        duplicateCollections(id).then(d=>{
            console.log(d)
            afterFunction(canceldubs,d)})
        }
    const virfycollection = ()=>{
        verfiycollection(id).then(d=>{
            afterFunction(cancelVerfiy , d)
        })
    }
    console.log('data' , collectionTable)
    return (
        <Box className='wrapperBox'>
            <LoadingBackdrop loading={loading} />
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            <Collapse in={filtering}>
                {filterOptions && <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />}
            </Collapse>
            <Grid className='firstRowContainer' container justifyContent="space-between">
                <div className='dashboardAndSelfBtnDiv'>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Collections' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div className='viewAndFilterBtnDiv'>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={handleFilter} />
                </div>
            </Grid>
            <Grid className='tableContainer' container>
                <NunitoText value='Collections' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid className='searchAndBtnContainer' container alignItems="center" justifyContent="space-between">
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={search} setState={onChangeSearch} onBlur />
                    <div className='endBtnDiv'>
                        <ReusableButton text='Add Collection' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            to='/addcollection' iconType='add' />
                        <ReusableButton text='Add To Competition' fontSize={15} bgColor='#144A94' height={46} marginRight={20}
                            onClick={() => onClickAddToCompetition()} iconType='add' />
                        <ReusableButton text='Mass Approve' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            onClick={() => onClickMassApprove()} iconType='check' />
                        <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46} marginRight={20}
                            onClick={() => onClickMassDelete()} iconType='delete' />
                    </div>
                    <NunitoText value='Search only in "Name", "Index", "School" and "Tuition Centre" column'
                        fontSize={20} fontWeight={400} italic color='#144A94' />
                </Grid>
                {loadingTable && <Loader height={600} />}
                {!loadingTable && collectionTable && makeSureIsArray(collectionTable.data).length &&
                    <HeavyTable setOnVerfiy={setOnVerfiy} headers={header} list={collectionTable} title='collection' rowsPerPage={rowsPerPage} setOnManagePermission={setOnManagePermission} setOnDups={setOnDups}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} fixed={['name', 'identifier']}
                        selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                        setId={setId} />}
                {!loadingTable && firstLoaded && collectionTable && !makeSureIsArray(collectionTable.data).length && <NoData height={600} />}
            </Grid>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <>
                    {Boolean(collectionTable) && <DeleteModal table={collectionTable} id={id} selected={selected}
                        deleteFunction={deleteFunction} cancelDelete={cancelDelete} />}
                </>
            </Modal>
            <Modal open={onApprove} onClose={() => cancelApprove()}>
                <>
                    {Boolean(collectionTable) && <ApproveModal table={collectionTable} id={id} selected={selected}
                        approveFunction={approveFunction} cancelApprove={cancelApprove} object='collection' />}
                </>
            </Modal>
            <Modal open={onManagePermission} onClose={() => cancelPermission()}>
               
              <>{Boolean(collectionTable) && <ManagePermission table={collectionTable} id={id} selected={selected}
                        approveFunction={approveFunction} cancelApprove={cancelApprove} object='collection' />}</>
            </Modal>
            <Modal open={onDup} onClose={() => canceldubs()}>
                <>
                {Boolean(collectionTable)&& <DuplicateTask canceldub={canceldubs} id={id} duplicate={duplicate} table={collectionTable} />}
                </>
            </Modal>
            <Modal open={verfiy} onClose={() => cancelVerfiy()}>
               
               <>{Boolean(collectionTable) && <VerfiyCollectionModal table={collectionTable} id={id} selected={selected}
                         approveFunction={virfycollection} cancelApprove={cancelVerfiy} />}</>
             </Modal>
        </Box >
    )
}