import React, { useState } from 'react'
import { FormControl, Select, IconButton, InputLabel, MenuItem, Box, Chip } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'
export default function ReusableSelect({ type, width, bgColor, borderColor,
    marginLeft, marginRight, marginTop, marginBottom,
    label, state, setState, required, readOnly, disabled, grayedOut,
    count, head, target,
    // For ReusableSelect
    options }) {
    const [open, setOpen] = useState(false)
    const expand = () => !readOnly && setOpen(true)
    const collapse = () => !readOnly && setOpen(false)
    const toggle = () => {
        if (!readOnly) {
            if (label !== undefined) document.getElementById(label).focus()
            setOpen(!open)
        }
    }
    let height = 54
    const formControlStyle = {
        marginLeft, marginRight, marginTop, marginBottom
    }
    const selectStyle = {
        minWidth: width,
        borderRadius: 12,
        backgroundColor: bgColor,
        border: required ? '1px solid red' : `1px solid ${borderColor}`
    }
    const btnStyle = {
        color: '#FFF',
        backgroundColor: '#144A94',
        borderRadius: '0 12px 12px 0',
        height: height,
        width: 52
    }
    const iconStyle = { fontSize: 36 }
    const inputLabelStyle = { height: height * 0.55, display: 'flex', alignItems: (!open && !state.toString().length) && 'flex-end' }
    const selectDisplayPropsStyle = { height: height - 16, display: 'flex', alignItems: 'center' }
    const menuProps = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        }
    }
    const formControlAttributes = {
        variant: 'outlined', style: formControlStyle, size: 'small'
    }
    const inputLabelAttributes = {
        shrink: Boolean(open || state.toString().length), style: inputLabelStyle
    }
    const onChangeState = value => {
        if (count !== undefined && head !== undefined && target !== undefined) {
            // For redux textfields
            return setState(count, head, target, value)
        }
        if (count !== undefined && target !== undefined) {
            // For states in an array of objects
            return setState(count, target, value)
        }
        // For states in an array only, object only or by itself
        if (count !== undefined) setState(count, value)
        else if (target !== undefined) setState(target, value)
        else setState(value)
    }
    const selectAttributes = {
        id: label, value: state, onChange: e => onChangeState(e.target.value),
        open, onClose: () => collapse(), onOpen: () => expand(),
        MenuProps: menuProps, SelectDisplayProps: { style: selectDisplayPropsStyle },
        IconComponent: () => <IconButton onClick={() => toggle()} style={btnStyle}>
            {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
        </IconButton>,
        style: selectStyle, readOnly, disabled
    }
    const boxStyle = { display: 'flex', flexWrap: 'wrap', gap: 5 }
    const chipStyle = { backgroundColor: '#f16774', color: '#FFF' }
    if (type === 'multipleFilter') {
        selectAttributes.multiple = true
        selectAttributes.renderValue = selected => (
            <Box style={boxStyle}>
                {selected.map(value => (
                    <Chip key={value} label={options.find(o => o.value === value)?.option} clickable style={chipStyle}
                        deleteIcon={<CancelIcon style={{ color: '#FFF' }} onMouseDown={e => e.stopPropagation()} />}
                        onDelete={() => onChangeState(selected.filter(s => s !== value))} />
                ))}
            </Box>
        )
    }
    if (type === 'type2') {
        selectStyle.width = width
        selectAttributes.SelectDisplayProps = null
    }
    if ((open || state.toString().length) && type !== 'type2') selectAttributes.label = label
    const inputLabelValue = label ? label.charAt(0).toUpperCase() + label.slice(1) : ''
    switch (type) {
        case 'filter':
        case 'multipleFilter':
            return (
                <FormControl {...formControlAttributes}>
                    <InputLabel {...inputLabelAttributes}>{inputLabelValue}</InputLabel>
                    <Select {...selectAttributes}>
                        {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            )
        case 'type2':
            return (
                <Select {...selectAttributes}>
                    {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                </Select>
            )
        case 'modal':
            return (
                <FormControl variant="outlined" style={formControlStyle} size='small'>
                    <InputLabel style={inputLabelStyle}>{inputLabelValue}</InputLabel>
                    <Select style={selectStyle} value={state} onChange={e => setState(e.target.value)} label={label}
                        open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} MenuProps={menuProps}
                        IconComponent={() => (
                            <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
                            </IconButton>)}
                    >
                        {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            )
        default: return null
    }
}